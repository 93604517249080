// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/customer/provider.ts"
);
import.meta.hot.lastModified = "1740495432000";
}
// REMIX HMR END

import {
  AddCustomerToMyCustomerManagedGroupInput,
  AddCustomerToMyCustomerManagedGroupMutationVariables,
  MutationRemoveCustomerFromMyCustomerManagedGroupArgs,
  UpdateCustomerManagedGroupMemberInput,
  QueryRsv_ClassInstancesByAttendanceArgs,
  OrderListOptions,
} from '~storefront/generated/graphql'

import { QueryOptions, sdk } from '~storefront/graphqlWrapper'

export function activeCustomerManagedGroupAdministrator(options: QueryOptions) {
  return sdk.activeCustomerManagedGroupAdministrator(undefined, options)
}

export async function getRsvClassInstancesByAttendance(
  input: QueryRsv_ClassInstancesByAttendanceArgs,
  options: QueryOptions,
) {
  return sdk.rsvClassInstancesByAttendance(input, options)
}

export async function myCustomerManagedGroup(options: QueryOptions) {
  return sdk.myCustomerManagedGroup(undefined, options)
}

export async function addCustomerToMyCustomerManagedGroup(
  input: AddCustomerToMyCustomerManagedGroupMutationVariables,
  options: QueryOptions,
) {
  return sdk
    .addCustomerToMyCustomerManagedGroup(input, options)
    .then((res) => res.addCustomerToMyCustomerManagedGroup)
}

export async function updateCustomerManagedGroupMember(
  input: UpdateCustomerManagedGroupMemberInput,
  options: QueryOptions,
) {
  return sdk
    .updateCustomerManagedGroupMember({ input }, options)
    .then((res) => res.updateCustomerManagedGroupMember)
}

export async function removeCustomerFromMyCustomerManagedGroup(
  customerId: MutationRemoveCustomerFromMyCustomerManagedGroupArgs,
  options: QueryOptions,
) {
  return sdk
    .removeCustomerFromMyCustomerManagedGroup(customerId, options)
    .then((res) => res.removeCustomerFromMyCustomerManagedGroup)
}

export function getActiveCustomer(options: QueryOptions) {
  return sdk.activeCustomer(undefined, options)
}

export function getActiveCustomerOrderList(
  orderListOptions: OrderListOptions,
  options: QueryOptions,
) {
  return sdk.activeCustomerOrderList({ orderListOptions }, options)
}

export async function getSelectedCustomer(
  customerId: string,
  options: QueryOptions,
) {
  const customerManagedGroup = await sdk.myCustomerManagedGroup(
    undefined,
    options,
  )
  const selectedCustomer =
    customerManagedGroup.myCustomerManagedGroup?.customers.find(
      (c) => c.customerId === customerId,
    )
  if (!selectedCustomer) {
    console.log('Customer ' + customerId + ' not found')
    return undefined
  }
  return selectedCustomer
}

export function getActiveCustomerDetails(options: QueryOptions) {
  return sdk.activeCustomerDetails(undefined, options)
}

export function getSelectedCustomerDetails(options: QueryOptions) {
  // return sdk.selectedCustomerDetails(undefined, options);
}

export function getActiveCustomerAddresses(options: QueryOptions) {
  return sdk.activeCustomerAddresses(undefined, options)
}

export function getFilteredGroupMembers(
  options: QueryOptions,
  productVariantId: string,
) {
  return sdk.filteredGroupMembers({ productVariantId }, options)
}

export function getSelectedCustomerAddresses(options: QueryOptions) {
  // return sdk.selectedCustomerAddresses(undefined, options);
}

export function ordersForMyCustomerManagedGroup(
  orderListOptions: OrderListOptions,
  options: QueryOptions,
) {
  return sdk.ordersForMyCustomerManagedGroup({ orderListOptions }, options)
}
